<template>
  <div class="reset-pass">
    <div class="reset-pass_logo">
        <v-img src="../../assets/images/logo-01.svg"></v-img>
    </div>
    <div class="reset-pass_title">
        Enter new password
    </div>
    <state-indicate v-slot="{usePromise, isLoading}">
     <v-form class="mt-n10" v-model="valid" @submit.prevent="usePromise(submit())" style="width: 20rem">
      <v-container>
        <v-row no-gutters>
          <v-alert
            v-if="error"
            outlined
            type="error"
            border="left"
          >
            <span class="text-caption" v-html="error"></span>
          </v-alert>
          <v-col>
            <v-text-field
              v-model="user.password"
              label="New Password"
              type="password"
              :rules="passwordRules"
              required
              dense
              outlined
              placeholder="Password"
              height="60"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-btn
          :loading="isLoading"
          type="submit"
          color="primary"
          width="100%"
          x-large
          class="mt-3 rounded-lg"
        >Save
        </v-btn>
      </v-container>
    </v-form>
    </state-indicate>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  props: ['token'],
  name: 'Reset-pass',
  data: () => ({
    error: null,
    valid: false,
    user: {
      password: '',
    },
    passwordRules: [
      v => !!v || 'Password is required',
      v => v.length > 7 || 'Password length must be more than 7 characters',
    ],
  }),
  
  methods:{
    ...mapActions('auth', ['updatePassword','dialog']),
    async submit() {
      this.error = null;
      const res = await this.updatePassword({ 
          email: this.email, 
          password: this.user.password,
          token: this.token
        }).then(ApiResponse => {
          this.$router.push('/')
        }).catch(ApiResponse => {
          this.error = res.data;
      })
    }
  },
  computed: {
    email() {
      return this.email = this.$route.query.mail
    }
  }
}
</script>

<style lang="sass">
.reset-pass
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    height: 100vh
    &_logo
      width: 33rem
      margin: 0 auto
      & img
        width: 100%
    &_title
      width: 100%
      margin-top: 3.5rem
      margin-bottom: 6rem
      text-align: center
      font-size: 3rem
      font-weight: 700
      color: #302e2e
@media screen and (max-width: 991px)
  .reset-pass
    &_logo
      width: 15rem
    &_title
      font-size: 2rem
   
</style>